<template>
  <div id="pub_3d_viewer">
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import __C from '@/primitives/_constant_'
import __M from 'moment'
import * as THREE from 'three/build/three.module.js'
import { TokenCheckService }  from "@/services"
import { mapActions } from 'vuex'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader'
import { BrightnessContrastShader } from 'three/examples/jsm/shaders/BrightnessContrastShader'
import Loading from '@/mixins/loading.mixin'
import * as d3 from 'd3'

export default {
  mixins: [
    Loading
  ],
  data: () => ({
    tokenCheckService: null,

    scene: null,
    camera: null,
    renderer: null,
    mixer: null,
    controls: null,
    composer: null,

    dirLight: null,
    clock : null,

    loader:  null,
    model:  null,

    width: 330,
    height: 300,

    errorOccurred: false
  }),
  watch: {
    width(val) {
      this.onCanvasResize()
    },
    '$route.params': {
      handler() {
        // this.callComponent()
      },
      deep: true
    },
    // model: {
    //   handler() {
    //     this.drawModel()
    //   },
    //   deep: true
    // }
  },
  created() {
    this.tokenCheckService = new TokenCheckService()
    this.setWrapperOverflowHidden(true)
    this.setWrapperOverflowWidth(this.width)
  },
  mounted() {
    this.loading = false
    // this.loadModel()
    this.setModel()
    window.addEventListener('resize', this.onCanvasResize)    
  },
  destroyed () {
    this.setWrapperOverflowHidden(false)
    window.removeEventListener('resize', this.onCanvasResize)
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [ 
      'setWrapperOverflowHidden',
      'setWrapperOverflowWidth',
      'setWrapperOverflowDimention'
    ]),
    
    onCanvasResize() {
      let container = document.getElementById('pub_3d_viewer')
      container.style.minWidth = `${this.canvasWidth()}px`
      container.style.height = `${this.canvasHeight()}px`

      this.setWrapperOverflowWidth(this.canvasWidth())

      if(this.renderer) this.renderer.setSize(this.canvasWidth(), this.canvasHeight())
      // if(this.controls) this.controls.handleResize() // for the TrackballControls
    },
    canvasWidth() { return parseInt(this.width / window.devicePixelRatio) + 1 },
    canvasHeight() { return parseInt(this.height / window.devicePixelRatio) + 1 },
    loadModel() {
      try {
        let token = localStorage.getItem(__C.LOCAL_STORAGE_NAME.TOKEN)
        this.tokenCheckService.tokenCheck(token).then(res => {
          if (res) {
            this.setModel()
          }else {
            this.setModel('TBD')
          }
        })        
      } catch(e) {
        console.error(e)
      }
    },
    setModel(name=null) {
      //  Preprocessing ---
      let fbxName = this.$route.params.code
      let props = JSON.parse(this.$route.params.props)

      if(props.d == 'eq') var dirname = 'Equipments'
      else dirname = 'Blocks'

      let newName = (name ? name : fbxName)

      this.width = props.w
      this.height = props.h

      this.setWrapperOverflowDimention({ width: this.width, height: this.height})
      // ------------------

      let container = document.getElementById('pub_3d_viewer')

      this.loader = new FBXLoader()
			this.loader.load( 
        `${__C.HOST_NAME_RESOURCE}/CRISP/3DModels/${dirname}/${newName}.fbx`, 
        (object_) => {
          this.model = object_
          this.drawModel()
        },
        // called while loading is progressing
        function ( xhr ) {
          console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' )
        },
        // called when loading has errors
        ( error ) => {
          // console.log(error)
          if(!name || !this.errorOccurred) {
            setTimeout(() => { this.setModel('NONOBJ') })
            this.errorOccurred = true
          }
        } 
      )
    },
    drawModel() {
      let container = document.getElementById('pub_3d_viewer')

      this.clock = new THREE.Clock()
      this.camera = new THREE.PerspectiveCamera( 65, this.width / this.height, 1, 2000 )
      this.camera.position.set(-1, 3, 6)

      this.scene = new THREE.Scene()
      this.scene.background = new THREE.Color(0xffffff)
      this.scene.add( new THREE.AmbientLight( 0x111122 ) )

      let light = new THREE.HemisphereLight(0xc0c0c0, 0x757575)
      light.position.set( 5, 10, 7.5 );
      this.scene.add(light)

      let spotLight = new THREE.SpotLight( 0xffffff, .35 )
      spotLight.position.set( 2.7, 35, 25 )
      spotLight.angle = Math.PI / 4
      spotLight.penumbra = 0.05
      spotLight.decay = 2
      spotLight.distance = 100

      spotLight.castShadow = true
      spotLight.shadow.mapSize.width = 1024
      spotLight.shadow.mapSize.height = 1024
      spotLight.shadow.camera.near = 10
      spotLight.shadow.camera.far = 200
      this.scene.add( spotLight )

      this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true })
      this.renderer.setPixelRatio( window.devicePixelRatio )
      // this.renderer.setSize(this.canvasWidth(), this.canvasHeight())
      this.onCanvasResize()
      this.renderer.shadowMap.enabled = true
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
      this.renderer.outputEncoding = THREE.sRGBEncoding
      container.appendChild( this.renderer.domElement )

      let brightness = new ShaderPass(BrightnessContrastShader)
      brightness.uniforms.brightness.value = 0.1
      brightness.uniforms.contrast.value = 0.05
      brightness.enabled = true

      // Antialiasing
      let fxwa = new ShaderPass(FXAAShader)
      fxwa.uniforms[ 'resolution' ].value.x = 1 / this.canvasWidth()
      fxwa.uniforms[ 'resolution' ].value.y = 1 / this.canvasHeight()

      this.composer = new EffectComposer(this.renderer)
      this.composer.addPass(new RenderPass(this.scene, this.camera))
      this.composer.addPass(brightness)
      this.composer.addPass(fxwa)

      this.controls = new OrbitControls( this.camera, this.renderer.domElement )
			this.controls.target.set( 0, 0, 0 )
      this.controls.enableDamping = true    // smooth spinning
			this.controls.autoRotate = true
      this.controls.update()
      
      // this.controls = new TrackballControls( this.camera, this.renderer.domElement )
      // this.controls.staticMoving = true  // smooth spinning
      // this.controls.rotateSpeed = 7
      // this.controls.zoomSpeed = 2
      // this.controls.panSpeed = 2
      // this.controls.update()

      let boundingBox = new THREE.Box3().setFromObject(this.model)
      let size = boundingBox.getSize()
      let defaultSize = {
        x: 6.5,
        y: 6.5,
        z: 6.5,
      }
      let scale = 1.
      let sValues = Object.values(size)
      let idx = sValues.indexOf(Math.max(...sValues))

      if(idx === 0) scale = defaultSize.x / size.x
      else if(idx == 1) scale = defaultSize.y / size.y
      else scale = defaultSize.z / size.z

      this.model.castShadow = true
      this.model.receiveShadow = true
      this.model.position.set(0, 0, 0)
      this.model.scale.set(this.model.scale.x * scale, this.model.scale.y * scale, this.model.scale.z * scale)

      this.model.traverse(function (child) {
        if (child instanceof THREE.Mesh) {
          child.castShadow = true
          child.receiveShadow = true
        }
      })

      this.scene.add(this.model)
      this.animate()
    },
    animate() {
      requestAnimationFrame(this.animate)
      this.controls.update()
      if(this.errorOccurred) {
        this.model.rotation.y += this.clock.getDelta() * .5
        this.model.rotation.y += this.clock.getDelta() * Math.PI / 180
      }
      this.renderer.render(this.scene, this.camera)
      this.composer.render()
    },
  }
}
</script>

<style lang="stylus" scoped>
#pub_3d_viewer
  min-width 34rem
  width 34rem
  height 30rem
  background-color #fff
  overflow hidden
  margin -1
</style>
